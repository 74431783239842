<template>
    <v-container fluid fill-height color="primary">
        <v-layout align-center justify-center>
            <v-progress-circular
                indeterminate
                color="accent"
                :size="70"
                :width="7"
            />
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "ApplicationLoading"
    }
</script>

<style scoped>

</style>