<template>

  <v-menu bottom left>
    <template v-slot:activator="{ on }">
      <v-toolbar-title v-on="on">
        <span><img :src="icons[currentLanguage]" class="country-icon as-toggle"/></span>
        <v-icon dark>arrow_drop_down</v-icon>
      </v-toolbar-title>
    </template>
    <v-list>
      <v-list-item
        v-for="(value, lang) in otherLanguages"
        :key="value"
        @click="changeLanguage(lang)"
      >
        <v-list-item-title>
          <img :src="icons[lang]" class="country-icon" />{{value}}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

</template>

<script>
  import nlIcon from 'svg-country-flags/svg/nl.svg'
  import frIcon from 'svg-country-flags/svg/fr.svg'
  import deIcon from 'svg-country-flags/svg/de.svg'
  import esIcon from 'svg-country-flags/svg/es.svg'
  import enIcon from 'svg-country-flags/svg/gb.svg'

  import _ from 'lodash'
  import { Trans } from '@/plugins/Translation'

  export default {
    data: function() {
      return {
        dropdown1Active: false,
        icons: {
          nl: nlIcon,
          en: enIcon,
          fr: frIcon,
          de: deIcon,
          es: esIcon,
        }
      }
    },
    computed: {
      supportedLanguages () {
        return Trans.supportedLanguagesNames
      },
      currentLanguage () {
        return Trans.currentLanguage
      },
      otherLanguages () {
        return _.omit(Trans.supportedLanguagesNames, [Trans.currentLanguage])
      },
      currentLanguageName () {
        return Trans.currentLanguageName
      }
    },
    methods: {
      changeLanguage (lang) {
        this.$store.commit('setLanguage', lang)
        const to = this.$router.resolve({ params: { lang } })
        return Trans.changeLanguage(lang).then(() => {
          this.$router.push(to.location)
        })
      },
      isCurrentLanguage (lang) {
        return lang === this.currentLanguage
      },
      toggleLangaugeDropdown() {
        this.langaugeDropdownActive = !this.dropdown1Active
      }
    }
  }
</script>


<style>
  .country-icon {
      width: 20px;
      height: auto;
      display: inline-block;
      vertical-align: baseline;
      border: 1px solid #dee2e6;
      box-shadow: 0px 1px 3px rgba(24, 29, 38, 0.1);
      margin-right: 5px;
  }
</style>
