import Vue from 'vue'
import Router from 'vue-router'
import multiguard from 'vue-router-multiguard';
import store from '@/plugins/store'
import { Trans } from '@/plugins/Translation'

Vue.use(Router)

function isAuthenticated (to, from, next) {
  // If the state does not contains a token
  if (!store.state.token) {
    next('/') // they are not authorized, so redirect to login
  } else {
    next() // we are authorized, continue on to the requested route
  }
}

function isPreview(to, from, next) {
  // If the query does not contains a preview key, redirect to login
  if (!to.query.preview || !to.query.username) {
    // Should we populate the state with data required for the preview?
    next('/')
  } else {
    store.dispatch('preview', to.query).then(() => {
      next() // Only when a preview url is validate, continue
    })
    // .catch((err) => {
    //   console.error(err)
    //   next("/") // Unable to authenticate, redirect back to login
    // })
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return { x: 0, y: 0 }
  },
  routes: [
/**
 * @todo fix language prefix for login.
 */
    {
      path: '/',
      name: 'login',
      component: () => import('@/components/LoginForm.vue')
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPassword.vue')
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/ResetPassword.vue')
    },
    {
      path: '/create-password/:token',
      name: 'create-password',
      component: () => import('@/views/CreatePassword.vue')
    },
    {
      path: '/:lang',
      name: 'language-router',
      component: () => import(/* webpackChunkName: "localpage" */ '@/components/LocalPage.vue'),
      beforeEnter: Trans.routeMiddleware,
      children: [
          {
            path: 'preview/:company/:report/',
            name: 'previewPage',
            title: store.state.project.name,
            report: store.state.report,
            beforeEnter: multiguard([isPreview, Trans.routeMiddleware]),
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "reportpage" */ '@/views/ReportPage.vue')
          },
          {
            path: 'dashboard',
            name: 'dashboard',
            beforeEnter: multiguard([isAuthenticated, Trans.routeMiddleware]),
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
          },
          {
            path: ':company/:report/print',
            name: 'printPage',
            title: store.state.project.name,
            report: store.state.report,
            beforeEnter: multiguard([isAuthenticated, Trans.routeMiddleware]),
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "printpage" */ '@/views/PrintPage.vue')
          },
          {
            path: ':company/:report/:page',
            name: 'reportPage',
            title: store.state.project.name,
            report: store.state.report,
            beforeEnter: multiguard([isAuthenticated, Trans.routeMiddleware]),
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "reportpage" */ '@/views/ReportPage.vue')
          },
          {
            path: 'profile',
            name: 'profile',
            beforeEnter: multiguard([isAuthenticated, Trans.routeMiddleware]),
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
          },
      ]
    },
  ]
})
