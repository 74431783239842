<template>
  <v-footer color="primary" app inset>
    <v-layout justify-center align-center>
      <span class="white--text">&copy; {{ new Date().getFullYear() }} - Nolost B.V.

        <!-- Show refresh option when user is logged in -->
        <span v-if="$store.state.user" class="ml-4">
          <small>(</small>
          <small class="footer-option" @click.prevent="logout">Log out</small>
          - <small class="footer-option" @click.prevent="refreshData">Refresh data</small>
        </span>
        <!-- Show print option when looking at a report -->
        <span v-if="$route.params.report">
          - <small class="footer-option" @click.prevent="printReport">Print report</small>
        </span>
        <span v-if="$store.state.user">
          <small>)</small>
        </span>

      </span>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
    >
      Data updated!
    </v-snackbar>
  </v-footer>
</template>

<script>
  export default {
    name: "ApplicationFooter",
    data () {
      return {
        snackbar: false
      }
    },
    methods: {
      refreshData () {
        this.$store.dispatch('refreshData').then(() => {
          this.snackbar = true
          location.reload();
        })
      },
      printReport () {
        this.$router.push({name: 'printPage', params: { 
          company: this.$store.state.company.slug,
          report: this.$route.params.report
        }}).catch(error => {
          // Don't show an error if we're already on the printPage
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      },
      logout () {
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push({name: 'login'})
          })
      },

    }
  }
</script>

<style scoped>
  .footer-option{
    cursor: pointer
  }
</style>