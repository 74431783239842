<template>
  <div id="app">
    
    <v-app id="insights">

      <template v-if="$store.state.isLoading">
        <application-loading/>
      </template>
      <template v-else>
        
        <application-drawer v-if="$store.state.hasApplicationDrawer"/>

        <application-toolbar/>

        <v-main id="content">
          <v-banner v-if="$store.state.updateAvailable" sticky icon="mdi-refresh" :stacked="true">
            <span style="padding-top: 10px; display: block; float: left; font-style: italic;">
              An updated version available, please refresh
            </span>
            
            <v-btn color="orange" @click.prevent="refreshData" class="float-right">
              Refresh page
            </v-btn>
          </v-banner>
        
          <router-view/>
        </v-main>

        <application-footer />
      </template>
      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
      >
        Data updated!
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>

  import ApplicationToolbar from '@/components/ApplicationToolbar'
  import ApplicationDrawer from '@/components/ApplicationDrawer'
  import ApplicationFooter from '@/components/ApplicationFooter'
  import ApplicationLoading from '@/components/ApplicationLoading'

  export default {
    data () {
      return {
        snackbar: false
      }
    },
    components: {
      ApplicationToolbar,
      ApplicationDrawer,
      ApplicationFooter,
      ApplicationLoading,
    },
    methods: {
      refreshData () {
        this.$store.dispatch('refreshData').then(() => {
          this.snackbar = true
          location.reload();
        })
        this.$store.dispatch('setUpdateAvailable', false)
      },
    },
  }
</script>
