<template>
  <v-navigation-drawer fixed v-model="drawer" app width="300">

    <div :class="getEnvironmentColor()">
      <v-img      
        src="/img/Nolost_Logo_rgb.png"
        height="64"
        width="191"
      />
    </div>

    <v-divider></v-divider>

    <v-list>
      <template v-if="$store.state.settings.subset && $store.state.settings.subset.length > 0">

        <v-list-item class="shade">
          <v-list-item-content>
            <v-list-item-title>
              Subset:
            </v-list-item-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-list-item-subtitle v-on="on">
                  <span v-for="(filter, index) in $store.state.settings.subset" :key="index">
                    {{ filter.name }}
                    <span v-if="index+1 < $store.state.settings.subset.length">, </span>
                  </span>
                </v-list-item-subtitle>
              </template>
              <span v-for="(filter, index) in $store.state.settings.subset" :key="index">
                {{ filter.name }}
                <span v-if="index+1 < $store.state.settings.subset.length">, </span>
              </span>
            </v-tooltip>

          </v-list-item-content>
        </v-list-item>

      </template>

      <v-list-item :to="'/' + $store.state.language+'/dashboard'">
        <v-list-item-title>{{ $store.state.company.name }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list 
      :key="'report_' + reportIndex"
      v-for="(report, reportIndex) in reports">
      <v-list-group v-model="report.active">
        
        <template v-slot:activator>
          <v-list-item-title class="font-weight-bold">{{ report.title }}</v-list-item-title>
        </template>
        
        <!-- New chapter based report -->
        <template v-if="report.type === 'chapters'">
          <v-list-group sub-group
            :key="'chapter_' + chapterIndex"
            v-model="chapter.active"
            v-for="(chapter, chapterIndex) in report.content">

            <template v-slot:activator>
              <v-list-item-title class="font-weight-bold">{{ chapter.title }}</v-list-item-title>
            </template>
            
            <v-list>
              <v-list-item
                :to="'/' + $store.state.language + '/' + $store.state.company.slug + '/' + report.slug + '/' + page.slug"
                :key="'chapter_' + chapterIndex +'_page_' + pageIndex"
                v-for="(page, pageIndex) in chapter.pages || []">
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item>
            </v-list>

          </v-list-group>
        </template>
          
        <!-- Old pages based report -->
        <template v-if="report.type === 'pages'" >
          <v-list-item 
              :to="'/' + $store.state.language + '/' + $store.state.company.slug + '/' + report.slug + '/' + page.slug"
              :key="'page_' + pageIndex"
              v-for="(page, pageIndex) in report.content">
            <v-list-item-title>{{ page.title }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list-group>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
export default {
  name: "ApplicationDrawer",
  data() {
    return {
      reports: [],
      route: this.$route.params,
      report: undefined,
      openReport: undefined,
      openPage: undefined,
      openChapter: 0
    }
  },
  methods: {
    getReports() {
      // Set the active report
      this.reports = this.$store.state.reports.map((report) => {
        let isActive = report.slug === this.$route.params.report
        report.active = isActive

        if (report.type === "chapters" && this.$route.params.page) {
          let activePage = this.$route.params.page
          report.content.map(chapter => {
            if (chapter.pages.find(page => page.slug === activePage)) {
              chapter.active = true
            }
          })
        }
        return report
      })
    },
    updateDrawerState() {
      if (this.$route.params.report) {
        this.report = this.$store.state.reports.find(report => report.slug === this.$route.params.report)
        
        this.openReport = this.$route.params.report
        this.openPage = this.$route.params.page

        // console.log(this.openReport,this.openPage)
      }
    },
    getEnvironmentColor() {
      if (window.location.host.startsWith('localhost')) {
        return "dev"
      }
      if (window.location.host.startsWith('insights.uat.nolostcapital.nl')) {
        return "uat"
      }
      if (window.location.host.startsWith('insights.nolostcapital.nl')) {
        return "prod"
      }
    }
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.showApplicationDrawer
      },
      set() {

      }
    }
  },
  mounted() {
    this.getReports()
  },
  watch: {
    '$route': ['getReports', 'updateDrawerState'],
  },
}
</script>

<style scoped>
.shade {
  background-color: #f1f6fc;
}
.dev{
  background-color: blue!important;
  width: 100%
}
.uat{
  background-color: yellow!important;
}
</style>
