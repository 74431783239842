"use strict";

import Vue from 'vue';
import axios from "axios";
import store from './store'
import sha256 from 'js-sha256';
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  // filters: Vue.$store.state.settings,
  
};


const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {

    if(config.method == "get") {
      config.headers['Cache-Control'] = `max-age=86400` // cache for a day
    }

    // When previewing with key
    if (store.state.preview) {
      config.headers['Preview'] = true;
    }    
    
    // When previewing with username
    if (store.state.previewUsername) {
      config.headers['Preview-User'] = store.state.previewUsername;
    }    

    // Add authentication header 
    let token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }

    // Add Query params for filter and language, but first make sure there is an
    // URLSearchParams object to append to.
    if (config.params === undefined) {
      config.params = new URLSearchParams()
    } else if (!(config.params instanceof URLSearchParams)){
      config.params = new URLSearchParams(config.params)
    }

    // Language
    if (store.state.language) {
      config.params.append("language", store.state.language)
    }
    
    // State hash
    if (token && config.params) {
      config.params.append("id", sha256(token));
    }

    // UUID nonce (used for breaking caching..)
    let nonce = localStorage.getItem('nonce');
    if (nonce) {
      config.params.append("nonce", sha256(nonce));
    }

    // Add the report slug to the query
    if (store.state.currentReport) {
      config.params.append("report", store.state.currentReport)
    }
    
    let selectedBenchmarkProjects = store.state.currentBenchmarks.filter(benchmark => benchmark.type === 'benchmark_project')
    if (selectedBenchmarkProjects.length > 0) {
      selectedBenchmarkProjects.map(selectedBenchmarkProject => {
        config.params.append("benchmarks_projects[]", selectedBenchmarkProject.id)
      })
    }

    // New implementation for filters 
    if (store.state.availableFilters.length > 0 && store.state.currentFilters.length > 0) {
      store.state.currentFilters.map(currentFilter => {
        config.params.append("filters[]", store.state.availableFilters.findIndex(availableFilter => availableFilter.name === currentFilter.name))
      })
    }

    return config;
  },

  (error) => {
      return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {

      // Check if the report has been modified
      if (response.headers['research-version']) {
        if (store.state.version != response.headers['research-version']) {
          store.dispatch('setUpdateAvailable', true)
        }
      } else if (response.headers['report-modified-at'] && store.state.currentReport) {
        let lastModifiedAt = new Date(response.headers['report-modified-at'])
        let currentReport = store.state.reports.find(r => r.slug === store.state.currentReport)
        
        // If the modified date is bigger or does not exist in the curent report, an updated report is available, thus we store this in the state
        if (!currentReport.modified || new Date(currentReport.modified) < lastModifiedAt) {
          store.dispatch('setUpdateAvailable', true)
        }
      }
      return response;
    },
    function(error) {
    // Do something with response error
        return Promise.reject(error);
    }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
