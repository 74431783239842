<template>
  <span>
    <v-autocomplete
      v-if="report"
      v-model="currentFilters"
      prepend-icon="filter"
      dense
      :hide-details="true"
      :items="availableFilters"
      :clearable="false"
      :label="$tc('interface.filterlabel')"
      item-text="name"
      item-value="name"
      return-object
      multiple>
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">
          {{ $tc('interface.filterselected', currentFilterCount) }}
        </span>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
export default {
  name: "FilterSelector",
  data () {
    return {
      report: undefined,
      availableFilters: [],
      currentFilters: this.$store.state.currentFilters || []
    }
  },
  computed: {
    currentFilterCount () { return this.$store.state.currentFilters.length }
  },
  methods: {
    setAvailableFilters() {
      this.report = this.$store.state.reports.find(report => report.slug === this.$route.params.report)
      
      if (this.report) {
        if (this.$store.state.settings.allowed_filters && this.$store.state.settings.allowed_filters.length > 0) {
          // Do a deep copy to seperate from compares
          this.availableFilters = JSON.parse(JSON.stringify(this.$store.state.settings.allowed_filters))
        } else {
          // Do a deep copy to seperate from compares
          this.availableFilters = JSON.parse(JSON.stringify(this.report.filters))
        }
      }      

      this.$store.dispatch('setAvailableFilters', this.availableFilters)
      this.setCurrentFilters()
      this.updateCurrentFilters(this.currentFilters)
    },
    setCurrentFilters() {
      this.$store.dispatch('setCurrentFilters', this.currentFilters)
    },
    updateCurrentFilters(filters) {
      if (filters.length > 0) {
        // Disable filters that are not the samen
        let filterKey = filters[0].key
        this.availableFilters.map(filter => {
          if (filter.key !== filterKey) {
            filter.disabled = true
          }
        })
      } else {
        // Enable all filters if no filters are selected
        this.availableFilters.map(filter => {
          filter.disabled = false
        })
      }
      this.currentFilters = filters
    }
  },
  watch: {
    '$route': 'setAvailableFilters',
    '$store.state.currentFilters': "updateCurrentFilters",
    'currentFilters': 'setCurrentFilters'
  }
}
</script>

