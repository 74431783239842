<template>
  <v-app-bar
      color="primary"
      dark
      fixed
      app
      :extended="isExtended()"
      extension-height="38">

    <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>

    <v-toolbar-title>Insights</v-toolbar-title>

    <v-spacer />

    <filter-selector style="margin-right: 20px"></filter-selector>
    <compare-selector style="margin-right: 20px"></compare-selector>
    <benchmark-selector style="margin-right: 20px"></benchmark-selector>

    <the-language-switcher />

    <v-menu offset-y v-if="$store.state.user">
      <template v-slot:activator="{ on }">
        <v-avatar color="accent" v-on="on">
          <span class="white--text headline"> {{ getInitial() }}</span>
        </v-avatar>
      </template>
      <v-list>
        <v-list-item :to="'/' + $store.state.language + '/profile'">
          <v-list-item-title>{{ getUserName($store.state.user) }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <template v-if="$store.state.preview">
      <v-btn rounded color="warning" @click="exitPreview">Exit Preview</v-btn>
    </template>

    <template v-slot:extension v-if="isExtended()">
      <span v-if="$store.state.currentFilters && $store.state.currentFilters.length > 0">
        {{ $tc('interface.filterlabel') }}:
        <v-chip
            v-for="(filter, index) in $store.state.currentFilters"
            color="accent"
            class="ml-2"
            small
            :key="index"
            @input="removeCurrentFilter(filter)"
            close
            @click:close="removeCurrentFilter(filter)"
        >
          <span>{{ filter.name }}</span>
        </v-chip>
      </span>

      <span style="padding-left: 20px" v-if="$store.state.currentCompares && $store.state.currentCompares.length > 0">
        {{ $tc('interface.comparelabel') }}:
        <v-chip
            v-for="(compare, index) in $store.state.currentCompares"
            color="accent"
            class="ml-2"
            small
            :key="index"
            @input="removeCurrentCompare(compare)"
            close
            @click:close="removeCurrentCompare(compare)"
        >
          <span>{{ compare.name }}</span>
        </v-chip>
      </span>

      <span style="padding-left: 20px" v-if="$store.state.currentBenchmarks && $store.state.currentBenchmarks.length > 0">
        {{ $tc('interface.benchmarklabel') }}:
        <v-chip
          v-for="(benchmark, index) in $store.state.currentBenchmarks"
          color="accent"
          class="ml-2"
          small
          :key="index"
          @input="removeCurrentBenchmark(benchmark)"
          close
          @click:close="removeCurrentBenchmark(benchmark)"
        >
          <span v-if="benchmark.type == 'benchmark_project'"><strong>Project:</strong> {{ benchmark.name }}</span>
          <span v-else><strong>Benchmark:</strong> {{ benchmark.name }}</span>
        </v-chip>
      </span>

      <span style="margin-left: auto;" v-if="$store.state.currentBenchmarks.length > 0 || $store.state.currentCompares.length > 0 || $store.state.currentFilters.length > 0">
        <v-chip
          color="red float-right"
          class="ml-2"
          small
          close
          @click="removeAll()"
          @click:close="removeAll()"
        >Reset</v-chip>
      </span>

    </template>
  </v-app-bar>
</template>

<script>
// import FilterSelector from '@/components/FilterSelector'
import CompareSelector from '@/components/CompareSelector'
import FilterSelector from '@/components/FilterSelector'
import BenchmarkSelector from '@/components/BenchmarkSelector'
import TheLanguageSwitcher from '@/components/TheLanguageSwitcher'

export default {
  name: "ApplicationToolbar",
  components: {
    // FilterSelector,
    TheLanguageSwitcher,
    CompareSelector,
    BenchmarkSelector,
    FilterSelector,
  },
  data() {
    return {
      reportMenu: false,
    }
  },
  methods: {
    getUserName (user) {
      if (user.first_name && user.last_name) {
        return user.first_name + ' ' + user.last_name
      } else if (user.email) {
        return user.username
      } else {
        return user.username
      }
    },
    getInitial() {
      if (this.$store.state.user.username === undefined || this.$store.state.user.username == '') {
        return '?'
      }
      else {
        return this.$store.state.user.username.charAt(0).toUpperCase()
      }
    },
    toggleDrawer() {
      this.$store.commit('toggleApplicationDrawer')
    },
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push({name: 'login'})
          })
    },
    exitPreview: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push({name: 'login'})
          })
    },
    isExtended() {
      // If we are not looking at a report
      if (!this.$route.params.report) {
        return false
      }
      // If we have a filter, benchmark or compare selected
      if (this.$store.state.currentFilters.length > 0 || this.$store.state.currentBenchmarks.length > 0 || this.$store.state.currentCompares.length > 0) {
        return true
      }
      return false
    },
    removeCurrentCompare(compare) {
      // Make a copy of the store
      let compares = [...this.$store.state.currentCompares]
      let compareIndex = compares.findIndex(c => c === compare)
      
      compares.splice(compareIndex, 1)
      this.$store.dispatch('setCurrentCompares', compares)
    },
    removeCurrentBenchmark(benchmark) {
      let benchmarks = [...this.$store.state.currentBenchmarks]
      let benchmarkIndex = benchmarks.findIndex(b => b === benchmark)

      benchmarks.splice(benchmarkIndex, 1)
      this.$store.dispatch('setCurrentBenchmarks', benchmarks)
    },
    removeCurrentFilter(filter) {
      // Make a copy of the store
      let filters = [...this.$store.state.currentFilters]
      let filterIndex = filters.findIndex(f => f === filter)
      
      filters.splice(filterIndex, 1)
      this.$store.dispatch('setCurrentFilters', filters)
    },
    removeAll() {
      this.$store.dispatch('setCurrentCompares', []) 
      this.$store.dispatch('setCurrentBenchmarks', []) 
      this.$store.dispatch('setCurrentFilters', []) 
    }
  }
}
</script>

<style scoped>

</style>