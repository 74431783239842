<template>
  <span v-if="$store.state.availableBenchmarks.length > 0">
    <v-autocomplete
      v-if="report"    
      v-model="currentBenchmarks"
      prepend-icon="format_list_bulleted"
      dense
      :hide-details="true"
      :items="availableBenchmarks"
      :label="$tc('interface.benchmarklabel')"
      item-text="name"
      item-value="name"
      return-object
      multiple>
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">
          {{ $tc("interface.benchmarkselected", currentBenchmarkCount) }}
        </span>
      </template>
      <template v-slot:item="{ item }">
        <span v-if="item.type == 'benchmark_project'"><strong>Project:</strong> {{ item.name }}</span>
        <span v-else><strong>Benchmark:</strong> {{ item.name }}</span>
      </template> 
    </v-autocomplete>
  </span>
</template>

<script>

export default {
  name: "BenchmarkSelector",
  data() {
    return {
      report: undefined,
      availableBenchmarks: [],
      currentBenchmarks: this.$store.state.currentBenchmarks || []
    };
  },
  computed: {
    currentBenchmarkCount () { return this.$store.state.currentBenchmarks.length }
  },
  methods: {
    setAvailableBenchmarks() {
      this.report = this.$store.state.reports.filter(report => report.slug === this.$route.params.report)[0] || undefined

      if (this.report) {
        this.availableBenchmarks = this.report.benchmarks || []

        // If any reports are assigned for benchmarking, we add those too!
        // if (this.report.benchmarks_projects && this.report.benchmarks_projects.length > 0) {
          // If a compare-report isn't added to the benchmark list, we add it.
          this.report.benchmarks_projects.map(benchmarkProject => {
            if (this.availableBenchmarks.findIndex(benchmark => benchmarkProject.id === benchmark.id && benchmark.type === 'benchmark_project') === -1) {
              this.availableBenchmarks.push({
                type: "benchmark_project",
                ...benchmarkProject,
                name: "Project: " + benchmarkProject.name
              })
            }
          })
        // Otherwise, we reset on navigation, clearing the benchmarks when switching between reports.
        // } 

        this.$store.dispatch('setAvailableBenchmarks', this.availableBenchmarks)
      } 
    },
    setCurrentBenchmarks() {
      this.$store.dispatch('setCurrentBenchmarks', this.currentBenchmarks)
    },
    updateCurrentBenchmarks(benchmarks) {
      this.currentBenchmarks = benchmarks
    }
  },
  watch: {
    '$route': 'setAvailableBenchmarks',
    '$store.state.currentBenchmarks': "updateCurrentBenchmarks",
    'currentBenchmarks': "setCurrentBenchmarks",
  },
};
</script>

