<template>
  <span>
    <v-autocomplete
      v-if="report"
      v-model="currentCompares"
      prepend-icon="balance"
      dense
      :hide-details="true"
      :items="availableCompares"
      :clearable="false"
      :label="$tc('interface.comparelabel')"
      item-text="name"
      item-value="name"
      return-object
      multiple>
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">
          {{ $tc('interface.compareselected', currentCompareCount) }}
        </span>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
export default {
  name: "CompareSelector",
  data () {
    return {
      report: undefined,
      availableCompares: [],
      currentCompares: this.$store.state.currentCompares || []
    }
  },
  computed: {
    currentCompareCount () { return this.$store.state.currentCompares.length }
  },
  methods: {
    setAvailableCompares() {
      this.report = this.$store.state.reports.filter(report => report.slug === this.$route.params.report)[0] || undefined

      if (this.report) {
        if (this.$store.state.settings.allowed_filters && this.$store.state.settings.allowed_filters.length > 0) {
          // Do a deep copy to seperate from filters
          this.availableCompares = JSON.parse(JSON.stringify(this.$store.state.settings.allowed_filters))
        } else {
          // Do a deep copy to seperate from filters
          this.availableCompares = JSON.parse(JSON.stringify(this.report.filters))
          this.availableCompares.map(compare => {
            compare.disabled = false
            return compare
          })
        }
      }

      this.$store.dispatch('setAvailableCompares', this.availableCompares)
      this.setCurrentCompares()
    },
    setCurrentCompares() {
      this.$store.dispatch('setCurrentCompares', this.currentCompares)
    },
    updateCurrentCompares(compares) {
      this.currentCompares = compares
    }
  },
  watch: {
    '$route': 'setAvailableCompares',
    '$store.state.currentCompares': "updateCurrentCompares",
    'currentCompares': 'setCurrentCompares'
  }
}
</script>

