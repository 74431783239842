import 'babel-polyfill'
import Vue from 'vue'
import '@/plugins/axios'
import '@/plugins/vuetify'
import App from '@/App.vue'
import router from '@/plugins/router'
import store from '@/plugins/store'
import vuetify from './plugins/vuetify';
import * as VueDeepSet from 'vue-deepset'
import * as Sentry from '@sentry/browser'
import i18n from '@/plugins/i18n'

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue: Vue,
    dsn: 'https://b6a6e4daec99449e9217865e9a39a8d7@sentry.io/1413768',
    attachProps: true,
  })
}

Vue.use(VueDeepSet)

Vue.filter('dutch_number', function (value) {
  if (!value) return '0'
  if (value == 10) return '10'
  return value.toString().replace('.', ',')
})

Vue.filter('zero_pad', function (value) {
  return value.toString().padStart(2, '0')
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
