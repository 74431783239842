import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { DEFAULT_LANGUAGE } from '@/constants/trans'
import { Trans } from '@/plugins/Translation'
import { v4 as uuidv4 } from 'uuid';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    hasApplicationDrawer: false,
    showApplicationDrawer: true,
    reportSettings: {},
    isLoading: false                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       ,
    status: '',
    token: localStorage.getItem('token') || '',
    settings: false,
    project: false,
    user: false,
    loginErrors: [],
    company: false,
    language: DEFAULT_LANGUAGE,
    currentFilters: [],
    availableFilters: [],
    currentCompares: [],
    availableCompares: [],
    currentBenchmarks: [],
    availableBenchmarks: [],
    currentReport: undefined,
    updateAvailable: false,
    version: undefined,
  },
  mutations: {
    addApplicationDrawer (state) {
      state.hasApplicationDrawer = true
    },
    removeApplicationDrawer (state) {
      state.hasApplicationDrawer = false
    },
    toggleApplicationDrawer (state) {
      state.showApplicationDrawer = !state.showApplicationDrawer
    },
    toggleLoading (state) {
      state.isLoading = !state.isLoading
    },
    setLoading (state) {
      state.isLoading = true
    },
    unsetLoading (state) {
      state.isLoading = false
    },
    authRequest(state){
      state.status = 'loading'
    },
    authSuccess(state, data){
      state.status = 'success'
      state.updated_at = new Date().toISOString()
      state.token = data.token
      state.user = data.user
      state.preview = data.preview || false
      state.previewUsername = data.previewUsername || undefined
      state.settings = data.settings || {}
      state.language = data.settings.language || DEFAULT_LANGUAGE
      state.project = data.project
      state.company = data.company
      state.reports = data.reports || []
      state.currentCompares = []
      state.availableCompares = []
      state.currentBenchmarks = []
      state.availableBenchmarks = []
      state.currentReport = undefined;
      state.currentFilters = []
      state.availableFilters = []
    },
    refresh_data(state, response){
      state.version = response.headers['research-version']
      state.updated_at = new Date().toISOString()
      state.user = response.data.user
      state.project = response.data.project
      state.company = response.data.company
      state.settings = response.data.settings
      state.reports = response.data.reports || []
    },
    update_profile(state, data){
      // Update the user profile
      state.user = {...state.user, ...data}
    },
    auth_error(state, response){
      state.status = 'error'
      state.loginErrors = JSON.parse(response)
    },
    setAvailableCompares(state, compares) {
      state.availableCompares = compares
    },
    setCurrentCompares(state, compares) {
      state.currentCompares = compares
    },
    setAvailableBenchmarks(state, benchmarks) {
      state.availableBenchmarks = benchmarks
    },
    setCurrentBenchmarks(state, benchmarks) {
      state.currentBenchmarks = benchmarks
    },
    setAvailableFilters(state, filters) {
      state.availableFilters = filters
    },
    setCurrentFilters(state, filters) {
      state.currentFilters = filters
    },
    setLanguage(state, language) {
      state.language = language
    },
    setReportSettings(state, settings) {
      state.reportSettings = settings
    },
    setUpdateAvailable(state, canUpdate) {
      state.updateAvailable = canUpdate
    },
    setCurrentReport(state, reportId) {
      // If we are switching reports, we clear the filters, compares and benchmarks
      if (reportId != state.currentReport) {
        state.currentCompares = []
        state.currentFilters = []
        state.currentBenchmarks = []
      }
      state.currentReport = reportId
    },
    logout(state){
      state.status = ''
      state.token = ''
      state.preview = false
      state.previewUsername = undefined
      state.user = false
      state.project = false
      state.hasApplicationDrawer = false
      state.company = false
      state.reports = []
      state.currentCompares = [] 
      state.availableCompares = [] 
      state.currentBenchmarks = [] 
      state.availableBenchmarks = [] 
      state.currentFilters = [] 
      state.availableFilters = [] 
      state.updateAvailable = false
    },
  },
  actions: {
    login({commit}, loginData){
      return new Promise((resolve, reject) => {
        commit('authRequest')
        Vue.axios.post(process.env.VUE_APP_API_URL + '/api-token-auth/', loginData)
        .then(resp => {
          const token = resp.data.token
          localStorage.setItem('token', token)
          Vue.axios.defaults.headers.common['Authorization'] = token
          commit('authSuccess', resp.data)
          commit('addApplicationDrawer')
          localStorage.setItem('nonce', uuidv4()) // Set nonce
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err.request.response)
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete Vue.axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    preview({commit}, query) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(process.env.VUE_APP_API_URL + `/insights/preview/?key=${query.preview}`)
        .then(resp => {
          let previewData = resp.data
          previewData.preview = true
          previewData.previewUsername = query.username
          previewData.token = query.preview
          localStorage.setItem('token', query.preview)
          Vue.axios.defaults.headers.common['Authorization'] = query.preview
          commit('authSuccess', previewData)
          commit('addApplicationDrawer')
          localStorage.setItem('nonce', uuidv4()) // Set nonce
          resolve(resp)
        })
        .catch(err => {
          commit('logout')
          localStorage.removeItem('token')
          delete Vue.axios.defaults.headers.common['Authorization']
          reject()
        })
      })
    },
    setLanguage({commit}, lang) {
      return new Promise((resolve) => {
        commit('setLanguage')
        localStorage.setItem('language', lang)
        Trans.changeLanguage(lang)
        resolve()
      })
    },
    setUpdateAvailable({commit}, canUpdate) {
      return new Promise((resolve, reject) => {
        commit("setUpdateAvailable", canUpdate)
      })
    },
    setCurrentCompares({commit}, compares){
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit("setCurrentCompares", compares)
      })
    },
    setAvailableCompares({commit}, compares){
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit("setAvailableCompares", compares)
      })
    },
    setCurrentBenchmarks({commit}, benchmarks){
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit("setCurrentBenchmarks", benchmarks)
      })
    },
    setAvailableBenchmarks({commit}, benchmarks){
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit("setAvailableBenchmarks", benchmarks)
      })
    },
    setCurrentReport({commit}, reportSlug){
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit("setCurrentReport", reportSlug)
      })
    },
    setCurrentFilters({commit}, filters){
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit("setCurrentFilters", filters)
      })
    },
    setAvailableFilters({commit}, filters){
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit("setAvailableFilters", filters)
      })
    },
    refreshData({commit}) {
      return new Promise((resolve, reject) => {
        if (this.state.token) {
          Vue.axios.get(process.env.VUE_APP_API_URL + '/insights/refresh/')
          .then(resp => {
            localStorage.setItem('nonce', uuidv4()) // Set nonce again, to prevent caching..
            commit("refresh_data", resp)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
        }
      })
    },
    updateProfile({commit}, updatedProfile) {
      return new Promise((resolve, reject) => {
        if (this.state.token) {
          Vue.axios.post(process.env.VUE_APP_API_URL + '/insights/profile/', updatedProfile)
          .then(resp => {
            commit("update_profile", updatedProfile)
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
        }
      })
    }
  },
  getters: {
    currentReport: (state) => {
      return state.currentReport
    }, 
    currentCompares: (state) => {
      return state.currentCompares
    },
    currentBenchmarks: (state) => {
      return state.currentBenchmarks
    },
    currentFilters: (state) => {
      return state.currentFilters
    },
    reportSettings: (state) => {
      const default_settings = {'min_results': 10}
      return {...default_settings, ...state.reportSettings}
    },
  }
})
