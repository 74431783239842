import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import 'vuetify/src/stylus/app.styl'
import de from 'vuetify/es5/locale/de'
import es from 'vuetify/es5/locale/es'
import fr from 'vuetify/es5/locale/fr'
import nl from 'vuetify/es5/locale/nl'
import i18n from './i18n'

// https://stackoverflow.com/questions/55877576/how-to-upgrade-from-vuetify-1-5-to-2-0
import 'vuetify/src/styles/main.sass'

Vue.use(Vuetify);

const opts = {
    icons: {
      iconfont: 'mdi',
    },
    lang: {
      locales: { de, es, fr, nl },
      current: 'nl',
      t: (key, ...params) => i18n.t(key, params)
    },
    theme: {
      dark: false,
      themes: {
        light: {
          primary: '#19255b',
          secondary: '#83929b',
          accent: '#009ade',
          error: '#e4003a',
          positive: '#00ac85',
          neutral: '#EF7D00',
          negative: '#E4003A',
          "positive-light": '#BFE8E0',
          "neutral-light": '#FBDEBF',
          "negative-light": '#F8BFCE',
          "neutral-center": '#E7E9EE',
          "neutral-outside": '#BFE6F5',
          blueshade: '#E5F5FB',
          gray: '#E0E4E6'
        },
        dark: {
          primary: '#19255b',
          secondary: '#83929b',
          accent: '#009ade',
          error: '#e4003a',
          positive: '#00ac85',
          neutral: '#EF7D00',
          negative: '#E4003A',
          "positive-light": '#BFE8E0',
          "neutral-light": '#FBDEBF',
          "negative-light": '#F8BFCE',
          "neutral-center": '#E7E9EE',
          "neutral-outside": '#BFE6F5',
          blueshade: '#E5F5FB',
          gray: '#E0E4E6'
        }
      }
    }
  }
export default new Vuetify(opts);